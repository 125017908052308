@import './colors_system';

$black-0: rgba(0, 0, 0, 0);
$charcoal-grey-40: rgba(40, 44, 49, 0.4);
$gray-100: #f6f7f8;
$gray-200: #e0e1e2;
$gray-500: #9298a0;
$gray-600: #626870;
$gray-700: #424850;

$disabled-color: #a0a0a0;

$primary:       #e13336 !default;
$secondary:     #868e96 !default;
$success:       #06e775 !default;
$info:          #00beff !default;
$warning:       #ffb800 !default;
$danger:        #e12653 !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$focus-blur:    #074c91 !default;
$focus-border:  #ffffff !default;
