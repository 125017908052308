@import 'brand_variables.scss';

$margin-between-menu: 26px;
$user-pic-size: 34px;
$lateral-navbar-items-right-space: 68px;
$lateral-navbar-items-right-space-xs: 24px;
$btn-login-padding-x: 24px;


.background-shadow {
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, .7), transparent);
  display: flex;
  height: 64px;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: $zindex-nav-carousel-shadow;
  width: 100%;

  .toastr {
    padding-top: 90px;
    padding-right: 300px;
    @include media-breakpoint-down(sm) {
      padding-top: 120px;
    }
  }

  @include media-breakpoint-up(md) {
    height: 80px;
  }
}

.toast-container .ngx-toastr .toast-close-button {
  font-size: 32px;
  top: -0.5em;
  font-weight: 500;
}

.ngx-toastr {
  border-radius: 2px;
}

.background-shadow,
.nav-container, .nav-background {
  height: $navbar-height;

  @include media-breakpoint-down(sm) {
    height: $navbar-height-sm;
  }
}

.nav-background {
  background-color: rgba(0, 0, 0, .5);
}

.navbar {
  /* navbar is set to sticky-top with no height because the modal  */
  height: 0;
  z-index: $zindex-nav-carousel-shadow + 1;

  .navbar-brand {
    background: $brand-navbar-top-background;
    box-shadow: $brand-navbar-box-shadow;
    height: $brand-navbar-top-height;
    margin-right: 0;
    position: relative;
    width: $brand-navbar-width;

    @include media-breakpoint-down(sm) {
      height: $brand-navbar-top-height-sm;
      width: $brand-navbar-width-sm;
    }

    img {
      padding: $brand-navbar-img-padding;

      @include media-breakpoint-down(sm) {
        padding: 6px;
      }
    }
  }

  /* fix for ios tablet */
  @include media-breakpoint-down(md) {
    position: fixed;
    top: 0;
  }
}

.navbar-nav {
  padding-left: $grid-gutter-width / 2;

  > .nav-item {
    margin-left: auto;
  }

  .nav-item {
    display: flex;

    .nav-link {
      font-size: 20px;
      line-height: 32px;
    }

    ~ .nav-item {
      margin-left: $margin-between-menu;
    }

    &.highlight {
      border: 2px solid white;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .nav-user-menu {
    margin-left: $margin-between-menu;
  }
}

.nav-item {
  .nav-link {
    color: white !important;
    padding: 0;

    &:hover {
      color: $gray-200 !important;
    }

    &.active {
      color: $primary !important;
    }

    &.login {
      padding: 12px $btn-login-padding-x !important;
    }

    &.nav-link-mobile {
      text-transform: none;
    }
  }
}

.lateral-navbar {
  background-color: $gray-700;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overscroll-behavior: contain;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .2s;
  width: 534px;
  z-index: 2;

  > * {
    padding-left: 66px;

    @include media-breakpoint-down(xs) {
      padding-left: 24px;
    }
  }

  &.uncollapsed {
    transform: translateX(0);
  }

  ul {
    margin-top: 32px;

    &.sub-links {
      margin-top: 30px;
      padding-left: 0;

      .nav-link {
        text-transform: none;
      }
    }
  }

  .top-section {
    align-items: flex-start;
    padding-right: $lateral-navbar-items-right-space;

    .right-section {
      padding-top: 16px;
    }

    @include media-breakpoint-down(xs) {
      padding-right: $lateral-navbar-items-right-space-xs;
    }
  }

  .user-link {
    background-color: $gray-700;

    .user-pic {
      height: $user-pic-size;
      width: $user-pic-size;
      right: 64px;
      box-shadow: 0 0 0px 3px $primary;
    }
  }

  .nav-item {
    width: 100%;
    font-weight: 400;

    .nav-link {
      font-size: 18px;
      line-height: 28px;
      padding: 15px 0 14px;
    }

    &:first-child {
      .nav-link {
        padding-top: 0;
      }
    }

    &:not(.highlight) {
      border-bottom: 1px solid $gray-600;
    }

    &.highlight {
      left: 0;
      margin-top: 32px;

      .login {
        border: 2px solid white;
        font-size: 19px;
        margin-right: $lateral-navbar-items-right-space;
        padding: 4px $btn-login-padding-x;
        text-align: center;

        @include media-breakpoint-down(xs) {
          margin-right: $lateral-navbar-items-right-space-xs;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 16px 0;
      }
    }
  }

  :last-child {
    &:not(.login){
      border-bottom: 0 !important;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.lateral-navbar-backdrop {
  background-color: rgba(67, 67, 67, 0.4);
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  transition: opacity .15s;
  width: 100vw;
  z-index: 1;

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
}

.navbar-toggle-wrapper {
  @include media-breakpoint-down(md) {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
  }

  @include media-breakpoint-down(sm) {
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.navbar-toggler {
  border: 0;
  margin-left: 28px;
  padding: 0;

  @include media-breakpoint-up(md) {
    top: 24px;
  }
}

@if $brand == $brand-bt {
  .brand-section {
    align-self: flex-start;
    position: absolute;
  }
}

@if $brand == $brand-formula {
  .navbar {
    .brand-section {
      align-self: center;
      margin-right: auto;
    }

    .nav-item {
      .nav-link {
        &.active {
          text-decoration: underline;
          font-weight: bold;
          color: white !important;
        }
      }
    }

    .navbar-brand {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .lateral-navbar {
    .top-section {
      align-items: center;
      padding-top: 20px;

      .navbar-brand {
        margin-top: 0;

        img {
          padding: 0;
        }
      }

      .right-section {
        padding-top: 0;
      }
    }
  }
}

.hidden {
  visibility: hidden !important;
}

.header-container {
  min-height: $navbar-height + 1;

  .header-image {
    height: 100%;
    filter: brightness(60%);
    object-fit: cover;
  }

  .header-text {
    position: absolute;
    top: 0;
    height: 100%;

    // Usado para manter o container no fundo da div de .header-text
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h1.title {
      font-size: 64px;
      line-height: 96px;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 48px;
      }
    }

    .call-to-action {
      margin-bottom: 128px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 52px;
      }
    }

    // Usado para corrigir o espaçamento do subtítulo quando não há botão de link
    .invisible-div {
      height: $button-height;

      @include media-breakpoint-down(sm) {
        height: $button-height-xs;
      }
    }

  }
}

.swiper-arrow-container {
  top: 0;
  height: 100%;
}
