
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-medium: #B1B1B1 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$scarlet: #b40016 !default;

$strong-primary: #e13336 !default;
$light-primary: rgba(225,51,54,0.15) !default;
// $primary:       $blue !default;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// Set a specific jump point for requesting color jumps
// $theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
// $yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark:             $gray-900 !default;
// $yiq-text-light:            $white !default;

$disabled-gray: #CDCDCD
